const theme = {
    veryLightBlue: '#d7effa',
    lightBlue: '#86d8ff',
    veryLightRed: '#fad1cd',
    lightRed: '#ffafa7',
    fg: '#424242',
    white: '#f1f1f1',
    lightGray: '#f8f8f8',
    gray: '#d3d3d3',
    bold: 'alegreya',
    fjalla: 'Fjalla One',
    normal: 'Source Sans Pro',
};

export default theme;