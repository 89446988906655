import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.footer`
    width: 100%;
    padding: 50px 0 20px 0;
    background: ${props => props.theme.white};
    text-align: center;
    h1 {
        margin: 0;
    }
    h2 {
        margin: 0;

    }

`
const A = styled.a`
    text-decoration: none;
    display: block;
    color: ${props => props.theme.fg};
    margin: auto;
    padding: 20px;
    &:hover {
        color: ${props => props.theme.lightBlue};
    }
`
const Nav = styled(Link)`
    text-decoration: none;
    color: ${props => props.theme.fg};
`

const Footer = () => {
    return (
        <Wrapper>
            <h1>KjG St. Barbara</h1>
            <h2>Freiburg / Littenweiler</h2>
            <A href="https://kiefer.media">Eine Gariproduktion von Kiefer.Media</A>
            <Nav to="/impressum">Impressum / Datenschutz</Nav>
        </Wrapper>
    )
};

export default Footer;