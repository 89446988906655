import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './config/theme';
import { Helmet } from "react-helmet";
import Footer from '../src/components/footer';

const GlobalStyle = createGlobalStyle`
    *, *:before, *:after {
        box-sizing: border-box;
    }
    html, body, #gatsby-focus-wrapper, #___gatsby {
        width: 100%;
        height: 100%;
    }  
    body {
        margin: 0;
        padding: 0;
        font-family: ${ props => props.theme.normal };
        color: ${ props => props.theme.fg };
        background: ${props => props.theme.veryLightBlue};
    }
    h1, h2, h3, h4, h5, h6 {
        text-align: center;
        font-family: ${ props => props.theme.bold };
        color: ${ props => props.theme.fg };
    }
`
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
const Content = styled.div`
    flex: 1;
`
const SiteWrapper = ({ children }) => (
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Helmet>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" rel="stylesheet" type="text/css" />
        </Helmet>
        <Wrapper>
            <Content>
            {children}
            </Content>
            <Footer />
        </Wrapper>
    </ThemeProvider>
);

export default SiteWrapper;